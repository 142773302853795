import type { ConfiguratorOptions } from '@/types/configurator'
import type { ModelObject, HubspotObject } from '@/types/products'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { usePodStore } from './pod'

export const useOptionStore = defineStore('option', () => {
  const options = ref([])

  function getModels(): ModelObject[] | [] {
    // return objects with parent_id = 39
    return options.value.filter((option: { parent_id: string }) => option.parent_id === '39')
  }

  function defaultPlanken(): 2 | 3 | 5 {
    const pod = usePodStore()
    return pod.getPlanken()
  }

  async function fetchData() {
    try {
      // Get JSON file with options data
      const res = await fetch(
        import.meta.env.VITE_API_URL +
          '/assets/bestanden/meshes/structuur2/__settings_prices_hubspot.json'
      )

      if (res.ok) {
        const data = await res.json()

        if (data.success === false) {
          console.error('Error fetching Modx data')
          return
        }

        // Store the data as an array of objects
        options.value = Object.values(data)
      } else {
        console.error('Error fetching Modx data')
      }
    } catch (error) {
      console.error('Timeout fetching Modx data')
    }
  }
  function calculatePrice(options: ConfiguratorOptions) {
    let price = 0

    // Wait for the canvas to be active
    if (typeof window.pod === 'undefined') {
      return 0
    }

    // Get the model from the options
    price += Number(calculatePriceModel(options))

    // Get the door from the options
    price += Number(calculatePriceDoor(options))

    price += Number(calculatePriceWindows(options))

    price += Number(calculatePriceAdditionalOptions(options))

    price += Number(calculatePriceTransport(options))

    price += Number(calculatePriceFoundation(options))

    return price
  }
  function calculatePriceModel(options: ConfiguratorOptions) {
    return getModel(options)?.prijs ?? 0
  }

  function calculatePriceDoor(options: ConfiguratorOptions) {
    return getDoor(options)?.prijs ?? 0
  }
  function calculatePriceWindows(options: ConfiguratorOptions) {
    const windows = getWindows(options)

    if (!windows) {
      return 0
    }

    let price = 0

    const vastPrice = Number(getObjectByHsId(windows.vast.hs_id)?.prijs ?? 0)
    const draaiPrice = Number(getObjectByHsId(windows.draai.hs_id)?.prijs ?? 0)
    const dakraamPrice = Number(getObjectByHsId(windows.dakraam.hs_id)?.prijs ?? 0)

    price += vastPrice * windows.vast.count
    price += draaiPrice * windows.draai.count
    price += dakraamPrice * windows.dakraam.count

    return price
  }
  function calculatePriceAdditionalOptions(options: ConfiguratorOptions) {
    const additionalOptions = getAdditionalOptions(options)

    if (!additionalOptions) {
      return 0
    }

    let price = 0

    // Loop over object keys
    for (const key in additionalOptions) {
      if (Object.prototype.hasOwnProperty.call(additionalOptions, key)) {
        const option = additionalOptions[key]
        if (key === 'casco' || key === 'minderprijs_combinatie') {
          price -= Number(getObjectByHsId(option.hs_id)?.prijs ?? 0) * option.count
        } else {
          price += Number(getObjectByHsId(option.hs_id)?.prijs ?? 0) * option.count
        }
      }
    }

    return price
  }
  function calculatePriceTransport(options: ConfiguratorOptions) {
    return getTransportOptions(options)?.prijs ?? 0
  }
  function calculatePriceFoundation(options: ConfiguratorOptions) {
    return getFoundation(options)?.prijs ?? 0
  }
  function calculatePriceCasco(options: ConfiguratorOptions) {
    const model = getModel(options)

    if (!model) {
      return 0
    }

    const hsId = model.opties_casco.find((option: { optie: string }) =>
      option.optie.includes('Minderprijs casco')
    )?.hs_id

    if (!hsId) {
      return 0
    }

    return Number(getObjectByHsId(hsId)?.prijs)
  }

  // Get objects
  function getModel(options: ConfiguratorOptions): ModelObject | null {
    // Find all options for the model
    const models = getModels()
    const type = options.podtype ?? 'beta'
    const size =
      type === 'beta'
        ? options.width.toFixed(1).replace('.', ',') +
          'm x ' +
          options.depth.toFixed(1).replace('.', ',') +
          'm'
        : options.depth.toFixed(1).replace('.', ',') +
          'm x ' +
          options.width.toFixed(1).replace('.', ',') +
          'm'
    const berging = options?.berging ?? false
    // Get the model
    return (
      models.find((model: { product: string }) => {
        const res = model.product.toLocaleLowerCase().includes(type) && model.product.includes(size)

        if (!berging) {
          return res && !model.product.includes('berging')
        }

        return res && model.product.includes('berging')
      }) ?? null
    )
  }

  function getDoor(options: ConfiguratorOptions): HubspotObject | null {
    const model = getModel(options)

    if (!model) {
      return null
    }

    let doorLabel = ''

    switch (options.door_type) {
      case 'enkeldraai':
        doorLabel = 'Enkele draaideur'
        break
      case 'dubbeldraai':
        doorLabel = 'Dubbele openslaande draaideuren'
        break
      case 'enkelschuif':
        doorLabel = 'Enkele schuifpui'
        break
      case 'dubbelschuif':
        doorLabel = 'Dubbele schuifpui'
        break
    }

    const hsId = model.opties_deur.find(
      (door: { optie: string }) => door.optie === doorLabel
    )?.hs_id

    if (!hsId) {
      return null
    }

    return getObjectByHsId(hsId)
  }

  function getWindows(options: ConfiguratorOptions) {
    const model = getModel(options)

    if (!model) {
      return null
    }

    const w = {
      vast: {
        hs_id: 0,
        count: 0
      },
      draai: {
        hs_id: 0,
        count: 0
      },
      dakraam: {
        hs_id: 0,
        count: 0
      }
    }

    options.windows.left.forEach((window: number) => {
      const type = window === 0 ? 'vast' : 'draai-/kiep'

      const hsId = model.opties_raam.find((raam: { optie: string }) =>
        raam.optie.includes(type)
      )?.hs_id

      if (!hsId) {
        return
      }

      if (type === 'vast') {
        w.vast.hs_id = hsId
        w.vast.count++
      } else {
        w.draai.hs_id = hsId
        w.draai.count++
      }
    })

    options.windows.right.forEach((window: number) => {
      const type = window === 0 ? 'vast' : 'draai-/kiep'

      const hsId = model.opties_raam.find((raam: { optie: string }) =>
        raam.optie.includes(type)
      )?.hs_id

      if (!hsId) {
        return
      }

      if (type === 'vast') {
        w.vast.hs_id = hsId
        w.vast.count++
      } else {
        w.draai.hs_id = hsId
        w.draai.count++
      }
    })

    // Dakraam
    if (options.dakraam) {
      const hsId = model.opties_raam.find((raam: { optie: string }) =>
        raam.optie.includes('Dakraam')
      )?.hs_id

      if (hsId) {
        w.dakraam.hs_id = hsId
        w.dakraam.count = options.dakraam
      }
    }

    return w
  }
  function getObjectByHsId(hsId: number): HubspotObject | null {
    return options.value.find((option: { hs_id: number }) => option.hs_id === hsId) ?? null
  }
  function getAvailableDoors(options: ConfiguratorOptions) {
    const model = getModel(options)
    return model?.opties_deur ?? []
  }
  function getAvailableWindows(options: ConfiguratorOptions) {
    const model = getModel(options)
    return model?.opties_raam ?? []
  }
  function getTransportOptions(options: ConfiguratorOptions): HubspotObject | null {
    if (options.distance === '') {
      return null
    }

    const model = getModel(options)

    if (!model) {
      return null
    }

    const hsId = model.opties_transport.find((transport: { optie: string }) =>
      transport.optie.includes(options.distance.replace('-', 'tot'))
    )?.hs_id

    if (!hsId) {
      return null
    }

    return getObjectByHsId(hsId)
  }

  function getAdditionalOptions(
    cOptions: ConfiguratorOptions
  ): { [key: string]: { hs_id: number; count: number } } | null {
    // Stop when the window.pod is not defined
    if (typeof window.pod === 'undefined') {
      return null
    }

    const model = getModel(cOptions)

    if (!model) {
      return null
    }

    const optionList: { [key: string]: { hs_id: number; count: number } } = {}

    if (cOptions.climate_control) {
      const hsId = model.opties_overig.find((overig: { optie: string }) =>
        overig.optie.includes('Climate Control')
      )?.hs_id

      if (hsId) {
        optionList['climate_control'] = {
          hs_id: hsId,
          count: 1
        }
      }
    }

    if (cOptions.floor_heating) {
      const hsId = model.opties_vloerverwarming.find((overig: { optie: string }) =>
        overig.optie.includes('Thermostaat')
      )?.hs_id

      if (hsId) {
        optionList['floor_heating'] = {
          hs_id: hsId,
          count: 1
        }
      }

      const hsId2 = model.opties_vloerverwarming.find((overig: { optie: string }) =>
        overig.optie.includes('per vierkante meter')
      )?.hs_id

      if (hsId2) {
        optionList['floor_heating_mtr'] = {
          hs_id: hsId2,
          count: Math.ceil(window.pod.getArea().Pod.area)
        }
      }
    }

    if (cOptions.sedum) {
      const hsId = model.opties_sedum.find((opties: { optie: string }) =>
        opties.optie.includes('Sedumdak')
      )?.hs_id

      if (hsId) {
        optionList['sedum'] = {
          hs_id: hsId,
          count: Math.ceil(window.pod.getArea().Roof.outer_area)
        }
      }
    }

    if (cOptions.optie_toilet) {
      let hsId = null

      if (cOptions.optie_sanitair_casco) {
        const hsIds: HubspotObject[] = options.value.filter(
          (option: { parent_id: string; product: string; hs_id: string }) =>
            option.parent_id === '282' && option.product.includes('Casco toiletruimte')
        )

        if (hsIds.length > 0) {
          hsId = hsIds[0].hs_id
        }
      } else {
        hsId = model.opties_sanitair.find((opties: { optie: string }) =>
          opties.optie.includes('Toiletruimte met sanitair')
        )?.hs_id
      }

      if (hsId) {
        optionList['toilet_met_sanitair'] = {
          hs_id: hsId,
          count: 1
        }
      }
    }

    if (cOptions.optie_badkamer) {
      let hsId = null

      if (cOptions.optie_sanitair_casco) {
        const hsIds: HubspotObject[] = options.value.filter(
          (option: { parent_id: string; product: string; hs_id: string }) =>
            option.parent_id === '282' && option.product.includes('Casco badkamerruimte')
        )

        if (hsIds.length > 0) {
          hsId = hsIds[0].hs_id
        }
      } else {
        hsId = model.opties_sanitair.find((opties: { optie: string }) =>
          opties.optie.includes('Badkamer met douchecabine, toilet, wastafelmeubel & boiler')
        )?.hs_id
      }

      if (hsId) {
        optionList['badkamer'] = {
          hs_id: hsId,
          count: 1
        }
      }

      if (cOptions.plaatsing_badkamer === 'midden' && cOptions.width >= 6) {
        let hsId = null

        const hsIds: HubspotObject[] = options.value.filter(
          (option: { parent_id: string; product: string; hs_id: string }) =>
            option.parent_id === '282' &&
            option.product.includes('Badkamerruimte gecentreerd in de pod')
        )

        if (hsIds.length > 0) {
          hsId = hsIds[0].hs_id
        }

        if (hsId) {
          optionList['badkamer_plaatsing'] = {
            hs_id: hsId,
            count: 1
          }
        }
      }
    }

    if (cOptions.optie_keuken) {
      const hsId = model.opties_sanitair.find((opties: { optie: string }) =>
        opties.optie.includes('Keukenblok met spoelbak, kraan en kastruimte')
      )?.hs_id

      if (hsId) {
        optionList['keuken'] = {
          hs_id: hsId,
          count: 1
        }
      }
    }

    if (cOptions.optie_keuken && (cOptions.optie_badkamer || cOptions.optie_toilet)) {
      let hsId = null

      const hsIds: HubspotObject[] = options.value.filter(
        (option: { parent_id: string; product: string; hs_id: string }) =>
          option.parent_id === '282' &&
          option.product === 'Minderprijs gecombineerde voorbereidingen keuken + sanitair'
      )

      if (hsIds.length > 0) {
        hsId = hsIds[0].hs_id
      }

      if (hsId) {
        optionList['minderprijs_combinatie'] = {
          hs_id: hsId,
          count: 1
        }
      }
    }

    if (cOptions.planken !== null && cOptions.planken !== defaultPlanken()) {
      // Get the first item in the array
      const hsId = model.opties_voorportaal[0]?.hs_id

      if (hsId) {
        optionList['planken'] = {
          hs_id: hsId,
          count: defaultPlanken() - cOptions.planken
        }
      }
    }

    if (cOptions.casco) {
      const hsId = model.opties_casco.find((overig: { optie: string }) =>
        overig.optie.includes('Minderprijs casco')
      )?.hs_id

      if (hsId) {
        optionList['casco'] = {
          hs_id: hsId,
          count: Math.ceil(window.pod.getPodArea().area)
        }
      }
    }

    if (cOptions.dark_cladding) {
      const hsId = model.opties_gevelbekleding.find((overig: { optie: string }) =>
        overig.optie.includes('Zwarte gevelbekleding')
      )?.hs_id

      if (hsId) {
        optionList['dark_cladding'] = {
          hs_id: hsId,
          count: Math.ceil(window.pod.getArea().Cladding.total_outer_length)
        }
      }
    }

    return optionList
  }

  function getFoundation(options: ConfiguratorOptions): HubspotObject | null {
    if (!options.foundation) {
      return null
    }

    const model = getModel(options)

    if (!model) {
      return null
    }

    const hsId = model.opties_fundering.find((fundering: { optie: string }) =>
      fundering.optie.includes('schroeffundering')
    )?.hs_id

    if (!hsId) {
      return null
    }

    return getObjectByHsId(hsId)
  }

  function getHsProducts(
    options: ConfiguratorOptions
  ): Record<string, {} | { id: number; aantal: number }> {
    const model = getModel(options)

    if (!model) {
      return {}
    }

    const products: Record<string, {} | { id: number; aantal: number }> = {}

    products['pod'] = {
      id: Number(model.hs_id),
      aantal: 1
    }

    if (options.door_type) {
      products['deur'] = {
        id: Number(getDoor(options)?.hs_id),
        aantal: 1
      }
    }

    if (options.distance !== '') {
      products['transport'] = {
        id: Number(getTransportOptions(options)?.hs_id),
        aantal: 1
      }
    }

    if (options.foundation) {
      products['fundering'] = {
        id: Number(getFoundation(options)?.hs_id),
        aantal: 1
      }
    }

    // Windows
    const windows = getWindows(options)

    if (windows) {
      if (windows.vast.count > 0) {
        products['raam_vast'] = {
          id: Number(windows.vast.hs_id),
          aantal: windows.vast.count
        }
      }

      if (windows.draai.count > 0) {
        products['raam_draai'] = {
          id: Number(windows.draai.hs_id),
          aantal: windows.draai.count
        }
      }

      if (windows.dakraam.count > 0) {
        products['raam_dakraam'] = {
          id: Number(windows.dakraam.hs_id),
          aantal: windows.dakraam.count
        }
      }
    }

    // Additional options
    const additionalOptions = getAdditionalOptions(options)

    if (additionalOptions) {
      // Loop over object keys
      for (const key in additionalOptions) {
        if (Object.prototype.hasOwnProperty.call(additionalOptions, key)) {
          products[key] = {
            id: Number(additionalOptions[key].hs_id),
            aantal: additionalOptions[key].count
          }
        }
      }
    }

    return products
  }

  return {
    options,
    fetchData,
    calculatePrice,
    calculatePriceModel,
    calculatePriceDoor,
    calculatePriceWindows,
    calculatePriceTransport,
    calculatePriceAdditionalOptions,
    calculatePriceCasco,
    getModel,
    getDoor,
    getWindows,
    getObjectByHsId,
    getAvailableDoors,
    getAvailableWindows,
    getTransportOptions,
    getAdditionalOptions,
    getModels,
    getHsProducts
  }
})
