<template>
  <div class="category">
    <div class="category-label">
      <span>Samenvatting</span>
    </div>
    <div>
      <p>Allle details op een rij.</p>
    </div>
    <div class="flex flex-col gap-2 py-2 option-group">
      <div v-for="s in selectedOptions" :key="s.label" class="flex flex-col">
        <span class="font-bold" v-text="s.label" />
        <span class="option-label" v-html="s.value" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useGtm } from '@/composables/useGtm';
import { usePodStore } from '@/stores/pod'

const pod = usePodStore()

const selectedOptions = [
  {
    label: 'Model',
    value: pod.options.podtype
  },
  {
    label: 'Afmeting (buitenmaats)',
    value:
      pod.options.width.toFixed(1).replace('.', ',') + ' m x ' + pod.options.depth.toFixed(1).replace('.', ',') + ' m' + ' = ' + Number(pod.options.width * pod.options.depth).toFixed(1).replace('.', ',') + ' m²'
  },
  {
    label: 'Oriëntatie',
    value: pod.options.orientation?.replace('_', ' ')
  },
  {
    label: 'Type deur',
    value: getDoorType()
  },
  {
    label: 'Ramen',
    value: getWindows()
  },
  {
    label: 'Opties',
    value: getOptions()
  },
  {
    label: 'Transport',
    value: pod.options.distance + ' van de weg tot aan de pod'
  },
  {
    label: 'Fundering',
    value: pod.options.foundation ? 'Schroeffundering' : 'Eigen fundering'
  },
  {
    label: 'Opmerkingen',
    value: pod.options.comment
  }
]

function getDoorType(): string {
  switch (pod.options.door_type) {
    case 'enkeldraai':
      return 'Enkele draaideur'
    case 'dubbeldraai':
      return 'Dubbele draaideur'
    case 'enkelschuif':
      return 'Enkele schuifpui'
    case 'dubbelschuif':
      return 'Dubbele schuifpui'
  }

  return ''
}

function getWindows(): string {
  let text = ''

  if (pod.options.windows.left.length > 0) {

    let w = [];

    for (const wI of pod.options.windows.left) {
      w.push((wI) ? 'draaikiep' : 'vastraam')
    }

    // Loop over windows
    text += `Links ${pod.options.windows.left.length} ramen, ${w.join(' / ')} <br>`
  }

  if (pod.options.windows.right.length > 0) {
    let w = [];

    for (const wI of pod.options.windows.right) {
      w.push((wI) ? 'draaikiep' : 'vastraam')
    }

    // Loop over windows
    text += `Links ${pod.options.windows.right.length} ramen, ${w.join(' / ')} <br>`
  }

  if (pod.options.dakraam && pod.options.dakraam > 0) {
    text += 'Dakraam 75 x 75cm'
  }

  if (text === '') {
    text = 'Geen ramen'
  }

  return text
}

function getOptions(): string {
  let text = ''

  // Climate control

  if (pod.options.climate_control) {
    text += 'Climate Control <br>'
  }

  // Vloerverwarming
  if (pod.options.floor_heating) {
    text += 'Vloerverwarming <br>'
  }

  // Groen sedumdak
  if (pod.options.sedum) {
    text += 'Groen Sedumdak <br>'
  }

  // Toiletruimte
  if (pod.options.optie_toilet) {
    text += 'Toiletruimte <br>'
  }

  // Badkamer
  if (pod.options.optie_badkamer) {
    text += 'Badkamer <br>'
  }

  // Keukenblok
  if (pod.options.optie_keuken) {
    text += 'Keukenblok <br>'
  }

  // Verkort voorportaal
  if (pod.options.kort_voorportaal) {
    text += 'Verkort voorportaal <br>'
  }

  // Casco uitvoeren (minderprijs)
  if (pod.options.casco) {
    text += 'Casco uitvoeren (minderprijs) <br>'
  }

  // Zwarte gevelbekleding
  if (pod.options.dark_cladding) {
    text += 'Zwarte gevelbekleding <br>'
  }

  if (text === '') {
    text = 'Geen extra opties gekozen'
  }
  return text;
}

useGtm().addEvent('configurator-stap-samenvatting')
</script>
<style scoped>
.option-label:first-letter {
  text-transform: uppercase;
}
</style>
